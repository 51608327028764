import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, first, switchMap } from 'rxjs';
import { GroupUser } from '../models/user/groupuser';
import { UserLicenseInfo } from '../models/user/userlicenseinfo';
import { IshtarLunchService } from './ishtarLunch.service';
import { Router } from '@angular/router';
import { CateringStoreFacade } from 'src/app/store/cateringStore/cateringStore.facade';

export const varlicense$ = new BehaviorSubject<UserLicenseInfo | undefined>(
  undefined
);

export const varusers$ = new BehaviorSubject<GroupUser[] | undefined>(
  undefined
);

export const vargroups$ = new BehaviorSubject<GroupUser[] | undefined>(
  undefined
);

export const varlatestProjectId$ = new BehaviorSubject<number | undefined>(
  undefined
);

export const vartranslations$ = new BehaviorSubject<any>({});

@Injectable({
  providedIn: 'root',
})
export class StartUpService {
  constructor(
    private IshtarLunchService: IshtarLunchService,
    private cateringStoreFacade: CateringStoreFacade,

    private router: Router
  ) {}

  getLicense() {
    this.IshtarLunchService.getLicense().subscribe((data) => {
      varlicense$.next(data);
    });
  }

  getUsers() {
    this.IshtarLunchService.getUsers().subscribe((data) => {
      varusers$.next(data);
    });
  }

  getGroups() {
    this.IshtarLunchService.getGroups().subscribe((data) => {
      vargroups$.next(data);
    });
  }

  getTranslations() {
    this.IshtarLunchService.getTranslations().subscribe((data) => {
      vartranslations$.next(data);
    });
  }

  boot() {
    this.getLicense();
    return varlicense$.asObservable().pipe(
      filter((license) => !!license),
      first(),
      switchMap(() => {
        this.getTranslations();
        this.getUsers();
        this.getGroups();
        return vartranslations$.pipe(
          filter((t) => !!Object.keys(t).length),
          first()
        );
      }),
      switchMap(() => this.cateringStoreFacade.getCateringStores$())
    );
  }
}
