import { createAction, props } from '@ngrx/store';
import { Category } from 'src/app/core/models/project/category';

export const getCategories = createAction(
    '[Category] Get Categories',
    props<{
      callback?: () => void;
    }>()
);

export const getCategoriesResolved = createAction(
    '[Category] Get CateringStore resolved',
    props<{category: Category[]}>()
);


export const deleteCategory = createAction(
    '[Category] Delete Category', 
    props<{ishtarLunchCategoryId: string; callback?: () => void }>()
);

export const deleteCategoryResolved = createAction(
    '[Category] Delete Category Resolved', 
    props<{ishtarLunchCategoryId: string}>()
);


export const updateCategory = createAction(
    '[Category] Update Category',
    props<{category: Category}>()
);
  
  export const updateCategoryResolved = createAction(
    '[Category] Update Category Resolved',
    props<{category: Category}>()
);


export const addCategory = createAction(
    '[Category] Add Category',
    props<{category: Category}>()
);

export const addCategoryResolved = createAction(
    '[Category] Add Category Resolved',
    props<{category: Category}>()
);