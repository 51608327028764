import { NgModule } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CoreModule } from '../core.module';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
@NgModule({
  declarations: [],
  imports: [
    CoreModule,
    MatSidenavModule,
    MatSelectModule,
    CommonModule,
    RouterModule,
    MatIconModule,
  ],
  exports: [
    MatSidenavModule,
    MatSelectModule,
    CommonModule,
    RouterModule,
    MatIconModule,
  ],
})
export class SharedModule {}
