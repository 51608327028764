import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { first, map, Subject } from 'rxjs';
import { AppState } from 'src/app/shared/store/app.reducer';
import { LoaderFacade } from 'src/app/shared/store/shared-feature/loader/loader.facade';
import {
  getCateringStores,
  getCateringStore,
  addCateringStore,
  updateCateringStore,
  deleteCateringStore,
} from './cateringStore.actions';
import { CateringStore } from '../../core/models/project/cateringStore';
import { cateringStoreState } from './cateringStore.reducer';

@Injectable({
  providedIn: 'root',
})
export class CateringStoreFacade {
  constructor(
    private _store: Store<AppState>,
    private loaderFacade: LoaderFacade
  ) {}

  cateringStores$ = this._store.pipe(
    // sla alle locatie op in cateringStores$ nadat de effects deze hebben opgehaald
    select(cateringStoreState),
    map((state) => state.cateringStores)
  );

  cateringStore$ = this._store.pipe(
    // sla de nodige locatie op in cateringStore$ nadat de effects deze hebben opgehaald
    select(cateringStoreState),
    map((state) => state.cateringStore)
  );

  getCateringStores$() {
    const subject = new Subject<void>();
    this._store.dispatch(
      getCateringStores({
        callback: () => {
          subject.next();
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  getCateringStore$(ishtarLunchCateringStoreId: string) {
    const subject = new Subject<CateringStore>();
    setTimeout(() => {
      this.cateringStores$
        .pipe(
          first(),
          map((cateringStores) =>
            cateringStores.find(
              (c) => c.ishtarLunchCateringStoreId == ishtarLunchCateringStoreId
            )
          )
        )
        .subscribe((p) => {
          if (p) {
            subject.next(p);
            subject.complete();
          } else {
            this._store.dispatch(
              getCateringStore({
                ishtarLunchCateringStoreId,
                callback: (cateringStore) => {
                  subject.next(cateringStore);
                  subject.complete();
                },
              })
            );
          }
        });
    }, 0);
    return subject.asObservable();
  }

  addCateringStore(cateringStore: CateringStore) {
    this._store.dispatch(addCateringStore({ cateringStore }));
  }

  deleteCateringStore(ishtarLunchCateringStoreId: string) {
    const id = this.loaderFacade.startLoading('Deleting cateringStore');
    const subject = new Subject<void>();
    this._store.dispatch(
      deleteCateringStore({
        ishtarLunchCateringStoreId,
        callback: () => {
          this.loaderFacade.stopLoading(id);
          subject.next();
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  updateCateringStore(cateringStore: CateringStore) {
    this._store.dispatch(updateCateringStore({ cateringStore }));
  }
}
