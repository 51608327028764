import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '../../app.reducer';
import { startLoading, stopLoading } from './loader.actions';
import { loaderSelectors } from './loader.selector';
import * as uuid from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class LoaderFacade {
  isLoading$ = this._store.pipe(select(loaderSelectors.isLoading));
  getLoaderText$ = this._store.pipe(select(loaderSelectors.getLoaderText));

  constructor(private _store: Store<AppState>) {}

  startLoading(text: string, action?: Observable<any>) {
    const id = uuid.v4();
    this._store.dispatch(startLoading({ id, text, action }));
    return id;
  }
  stopLoading(id: string) {
    this._store.dispatch(stopLoading({ id }));
  }
}
