import { createReducer, on, Action } from '@ngrx/store';
import { AppState } from 'src/app/shared/store/app.reducer';
import { Category } from 'src/app/core/models/project/category';
import {
  getCategoriesResolved,
  deleteCategoryResolved,
  updateCategoryResolved,
  addCategoryResolved
} from './category.actions';
import { state } from '@angular/animations';

export const featureSlice = 'category';

export interface State { // wat wil je allemaal opslaan?
  categories : Category[];
}

const defaultState: State = { //deze status is uw default, word opgeroepen wanneer de applicatie start
    categories: [],
};


export function Reducer(state: State | undefined, action: Action) {
  return categoryReducer(state, action);
}

export const initialState: State = defaultState;

export const categoryReducer = createReducer<State>(
  initialState, 

  on(getCategoriesResolved, (state, { category }) => ({ //wanneer er op de knop word gedrukt laden we alle categories op
    ...state,                                            //nemen de huidige staat
    categories: category.concat(state.categories.filter(c => !category.some(s => s.ishtarLunchCategoryId == c.ishtarLunchCategoryId))),
  })),

  on(deleteCategoryResolved, (state, { ishtarLunchCategoryId }) => ({ //wanneer er op de knop word gedrukt verwijderen we het meegegeven ID uit de rest en
    ...state,
    categories: state.categories.filter((l) => l.ishtarLunchCategoryId != ishtarLunchCategoryId),
  })),

  on(updateCategoryResolved, (state, { category }) => ({ //wanneer er op de knop word gedrukt gaan we 1 bepaalde category updaten
    ...state,
    categories: state.categories.map((c) => c.ishtarLunchCategoryId == category.ishtarLunchCategoryId ? category: c
  ),
  })),

  on(addCategoryResolved, (state, { category }) => ({ //wanneer er op de knop word gedrukt voegen we een category toe aan de DB
    ...state,
    categories: state.categories.concat    ([category]),
  })),
);

export const categoryState = (state: AppState) => state.coreFeature.category;