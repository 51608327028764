import { createReducer, on, Action } from '@ngrx/store';
import { AppState } from 'src/app/shared/store/app.reducer';
import { CateringStore } from 'src/app/core/models/project/cateringStore';
import {
  getCateringStoresResolved,
  getCateringStoreResolved,
  deleteCateringStoreResolved,
  updateCateringStoreResolved,
  addCateringStoreResolved,
} from './cateringStore.actions';
import { state } from '@angular/animations';

export const featureSlice = 'cateringStore';

export interface State {
  // wat wil je allemaal opslaan?
  cateringStores: CateringStore[];
  cateringStore?: CateringStore;
}

const defaultState: State = {
  //deze status is uw default, word opgeroepen wanneer de applicatie start
  cateringStores: [],
  cateringStore: undefined,
};

export function Reducer(state: State | undefined, action: Action) {
  return ArticleReducer(state, action);
}

export const initialState: State = defaultState;

export const ArticleReducer = createReducer<State>(
  initialState,

  on(getCateringStoresResolved, (state, { cateringStores }) => ({
    //wanneer er op de knop word gedrukt laden we alle cateringStores op
    ...state,
    cateringStores: cateringStores.concat(
      state.cateringStores.filter(
        (c) =>
          !cateringStores.some(
            (s) => s.ishtarLunchCateringStoreId == c.ishtarLunchCateringStoreId
          )
      )
    ),
  })),

  on(getCateringStoreResolved, (state, { cateringStore }) => ({
    ...state,
    cateringStore: cateringStore,
  })),

  on(deleteCateringStoreResolved, (state, { ishtarLunchCateringStoreId }) => ({
    //wanneer er op de knop word gedrukt filteren we het meegegeven ID uit de rest
    ...state,
    cateringStores: state.cateringStores.filter(
      (l) => l.ishtarLunchCateringStoreId != ishtarLunchCateringStoreId
    ),
  })),

  on(updateCateringStoreResolved, (state, { cateringStore }) => ({
    //wanneer er op de knop word gedrukt laden we alle cateringStores op
    ...state,
    cateringStores: state.cateringStores.map((c) =>
      c.ishtarLunchCateringStoreId == cateringStore.ishtarLunchCateringStoreId
        ? cateringStore
        : c
    ),
  })),

  on(addCateringStoreResolved, (state, { cateringStore }) => ({
    //wanneer er op de knop word gedrukt laden we alle cateringStores op
    ...state,
    cateringStores: state.cateringStores.concat([cateringStore]),
  }))
);

export const cateringStoreState = (state: AppState) =>
  state.coreFeature.cateringStore;
