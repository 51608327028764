import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap, tap, mergeMap } from 'rxjs/operators';
import { IshtarLunchService } from '../../core/services/ishtarLunch.service';
import {
  getCategories,
  getCategoriesResolved,
  deleteCategory,
  deleteCategoryResolved,
  updateCategory,
  updateCategoryResolved,
  addCategory,
  addCategoryResolved
} from './category.actions';

  @Injectable({ providedIn: 'root' })
  export class CategoryEffects {
    constructor(
      private actions$: Actions,
      private ishtarLunchService: IshtarLunchService
    ) {}

    getCategories = createEffect(() => 
      this.actions$.pipe ( // luister naar alle acties
        ofType(getCategories), // Zoek naar een actie genaamd getCategories
        mergeMap(({ callback }) =>
        this.ishtarLunchService.getCategories().pipe(  
          switchMap((category) =>
            of(
              getCategoriesResolved({
                category
              })
            )
          ),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  deleteCategory = createEffect(() =>
    this.actions$.pipe(  // luister naar alle acties
      ofType(deleteCategory),  // Zoek naar een actie genaamd deleteCategory
      switchMap(({ ishtarLunchCategoryId, callback}) =>
        this.ishtarLunchService.deleteCategory(ishtarLunchCategoryId).pipe(
          switchMap((data) =>
            of(deleteCategoryResolved({ ishtarLunchCategoryId: data[0] }))
          ),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  updateCategory = createEffect(() =>
  this.actions$.pipe(
    ofType(updateCategory),
    switchMap(({ category }) =>
      this.ishtarLunchService.updateCategory(category).pipe(
        switchMap((data) =>
          of(updateCategoryResolved({ category: data[0] }))
        ),
        catchError((e) => [])
      )
    )
  )
  );

  addCategory = createEffect(() =>
    this.actions$.pipe(
      ofType(addCategory),
      switchMap(({ category }) =>
        this.ishtarLunchService.addCategory(category).pipe(
          switchMap((data) =>
            of(addCategoryResolved({ category: data[0] }))
          ),
          catchError((e) => [])
        )
      )
    )
  );


}
