import * as fromCateringstore from './cateringStore/cateringStore.reducer';
import * as fromCategory from './category/category.reducer';

import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { CateringStoreEffects } from './cateringStore/cateringStore.effects';
import { CategoryEffects } from './category/category.effects';

export const featureKey = 'coreFeature';

export interface CoreState {
  [fromCateringstore.featureSlice]: fromCateringstore.State;
  [fromCategory.featureSlice]: fromCategory.State;

}

export const reducers = new InjectionToken<ActionReducerMap<CoreState>>(
  featureKey,
  {
    factory: () => ({
      [fromCateringstore.featureSlice]: fromCateringstore.Reducer,
      [fromCategory.featureSlice]: fromCategory.Reducer,
    }),
  }
);
export const effects = [CateringStoreEffects, CategoryEffects];
