import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { StoreModule } from '@ngrx/store';
import * as coreFeature from './store/core.feature'
import { EffectsModule } from '@ngrx/effects';



@NgModule({
  declarations: [

  ],
  
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    StoreModule.forFeature(coreFeature.featureKey, coreFeature.reducers),
    EffectsModule.forFeature(coreFeature.effects)
  ],
  exports: [CommonModule, RouterModule, MatIconModule],
})
export class CoreModule {}
