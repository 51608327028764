import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap, tap, mergeMap } from 'rxjs/operators';
import { IshtarLunchService } from '../../core/services/ishtarLunch.service';
import {
  deleteCateringStoreResolved,
  deleteCateringStore,
  getCateringStores,
  getCateringStoresResolved,
  getCateringStore,
  getCateringStoreResolved,
  updateCateringStore,
  updateCateringStoreResolved,
  addCateringStore,
  addCateringStoreResolved,
} from './cateringStore.actions';

@Injectable({ providedIn: 'root' })
export class CateringStoreEffects {
  constructor(
    private actions$: Actions,
    private ishtarLunchService: IshtarLunchService
  ) {}

  getCateringStores = createEffect(() =>
    this.actions$.pipe(
      // luister naar alle acties
      ofType(getCateringStores), // Zoek naar een actie genaamd getCateringStores
      mergeMap(({ callback }) =>
        this.ishtarLunchService.getCateringStores().pipe(
          switchMap((cateringStores) =>
            of(
              getCateringStoresResolved({
                cateringStores,
              })
            )
          ),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  getCateringStore = createEffect(() =>
    this.actions$.pipe(
      ofType(getCateringStore),
      mergeMap(({ ishtarLunchCateringStoreId, callback }) =>
        this.ishtarLunchService
          .getCateringStore(ishtarLunchCateringStoreId)
          .pipe(
            tap((cateringStore) =>
              callback ? callback(cateringStore) : undefined
            ),
            switchMap((cateringStore) =>
              of(getCateringStoreResolved({ cateringStore }))
            ),
            catchError((e) => [])
          )
      )
    )
  );

  deleteCateringStore = createEffect(() =>
    this.actions$.pipe(
      // luister naar alle acties
      ofType(deleteCateringStore), // Zoek naar een actie genaamd deleteCateringStore
      switchMap(({ ishtarLunchCateringStoreId, callback }) =>
        this.ishtarLunchService
          .deleteCateringStore(ishtarLunchCateringStoreId)
          .pipe(
            switchMap((data) =>
              of(
                deleteCateringStoreResolved({
                  ishtarLunchCateringStoreId: data[0],
                })
              )
            ),
            tap(() => (callback ? callback() : undefined)),
            catchError((e) => [])
          )
      )
    )
  );

  updateCateringStore = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCateringStore),
      switchMap(({ cateringStore }) =>
        this.ishtarLunchService.updateCateringStore(cateringStore).pipe(
          switchMap((data) =>
            of(updateCateringStoreResolved({ cateringStore: data[0] }))
          ),
          catchError((e) => [])
        )
      )
    )
  );

  addCateringStore = createEffect(() =>
    this.actions$.pipe(
      ofType(addCateringStore),
      switchMap(({ cateringStore }) =>
        this.ishtarLunchService.addCateringStore(cateringStore).pipe(
          switchMap((data) =>
            of(addCateringStoreResolved({ cateringStore: data[0] }))
          ),
          catchError((e) => [])
        )
      )
    )
  );
}
