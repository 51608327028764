import { GroupUser } from '../user/groupuser';
import { CateringStore } from './cateringStore';

export class OrderDaySettings {
  ishtarLunchOrderDaySettingsId?: string;
  name?: string;
  responsible?: GroupUser;
  day!: string;
  cateringStore!: CateringStore;
  latestOrderTime!: Date;

  constructor(obj: Partial<OrderDaySettings>) {
    Object.assign(this, obj);
  }
}
