import { OrderArticle } from './orderArticle';
import { Ingredient } from './ingredient';

export class OrderArticleIngredient {
  ishtarLunchOrderArticleIngredientId?: string;
  name?: string;
  orderArticle?: OrderArticle;
  ingredient!: Ingredient;
  included!: boolean;

  constructor(obj: Partial<OrderArticleIngredient>) {
    Object.assign(this, obj);
  }
}
