export class Order {
  ishtarLunchOrderId?: string;
  name!: string;
  user!: string;
  orderTime!: Date;

  constructor(obj: Partial<Order>) {
    Object.assign(this, obj);
  }
}
