import { Category } from './category';

export class CateringStore {
  ishtarLunchCateringStoreId?: string;
  name!: string;
  street!: string;
  housenumber!: string;
  zipcode!: string;
  city!: string;
  phoneNumber!: string;
  email?: string;
  logo?: string;

  constructor(obj: Partial<CateringStore>) {
    Object.assign(this, obj);
  }
}
