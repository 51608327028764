import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TopBarComponent } from './shared/component/top-bar/top-bar.component';
import { SharedModule } from './shared/share.module';
import { EffectsRootModule } from '@ngrx/effects';
import { CoreModule } from './core.module';
import { StoreRootModule } from '@ngrx/store';

import { varlicense$ } from 'src/app/core/services/startup.service';
import { map, filter, first } from 'rxjs';
import {
  MicrosoftAuthenticationGuard,
  GeneralLayoutComponent,
  SideBarAction,
} from 'processdelight-angular-components';

@Component({
  standalone: true,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    CommonModule,
    RouterModule,
    TopBarComponent,
    SharedModule,
    CoreModule,
    GeneralLayoutComponent,
  ],
})
export class AppComponent implements OnInit {
  title = 'IshtarLunch';

  orgLogo$ = varlicense$.pipe(map((o) => o?.logo));

  userName$ = varlicense$.pipe(map((u) => u?.name));
  userPhoto$ = varlicense$.pipe(map((u) => u?.photo));

  actions: SideBarAction[] = [
    new SideBarAction({
      title: 'Home',
      icon: 'home',
      iconOutline: true,
      route: ['home'],
    }),
    new SideBarAction({
      title: 'Order History',
      icon: 'history',
      iconOutline: true,
      route: ['history'],
    }),
    new SideBarAction({
      title: 'Orderlist',
      icon: 'list_alt',
      iconOutline: true,
      route: ['orderlist'],
    }),
    new SideBarAction({
      title: 'Settings',
      icon: 'settings',
      iconOutline: true,
      route: ['settings'],
    }),
  ];

  ngOnInit(): void {
    varlicense$
      .pipe(
        filter((l) => !!l && !!Object.keys(l).length),
        first()
      )
      .subscribe((l) =>
        l?.licenses.some((i) => i.productName == 'Ishtar.Lunch' && i.isAdmin)
          ? this.actions.push(
              new SideBarAction({
                title: 'Orderlist',
                icon: 'list_alt',
                iconOutline: true,
                route: ['orderlist'],
              }),
              new SideBarAction({
                title: 'Settings',
                icon: 'settings',
                iconOutline: true,
                route: ['settings'],
              })
            )
          : undefined
      );
  }
}

export const appRoutes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('processdelight-angular-components').then((m) => m.MsalModule),
  },
  {
    path: 'home',
    canActivate: [MicrosoftAuthenticationGuard],
    loadComponent: () =>
      import('./home/home.component').then((mod) => mod.HomeComponent),
    data: { breadcrumbTitle: 'Home' },
  },
  {
    path: 'history',
    canActivate: [MicrosoftAuthenticationGuard],
    loadComponent: () =>
      import('./order-history/order-history.component').then(
        (mod) => mod.OrderHistoryComponent
      ),
    data: { breadcrumbTitle: 'Order History' },
  },
  {
    path: 'orderlist',
    canActivate: [MicrosoftAuthenticationGuard],
    loadComponent: () =>
      import('./orderlist/orderlist.component').then(
        (mod) => mod.OrderlistComponent
      ),
    data: { breadcrumbTitle: 'Order List' },
  },
  {
    path: 'settings',
    canActivate: [MicrosoftAuthenticationGuard],
    loadComponent: () =>
      import('./settings/settings.component').then(
        (mod) => mod.SettingsComponent
      ),
    data: { breadcrumbTitle: 'Settings' },
  },
  {
    path: 'settings-addLocation',
    canActivate: [MicrosoftAuthenticationGuard],
    loadComponent: () =>
      import(
        './settings/settings-addLocation/settings-addLocation.component'
      ).then((mod) => mod.SettingsAddLocationComponent),
    data: { breadcrumbTitle: 'Settings - Add Location' },
  },
  {
    path: 'settings-editLocation',
    canActivate: [MicrosoftAuthenticationGuard],
    loadComponent: () =>
      import(
        './settings/settings-editLocation/settings-editLocation.component'
      ).then((mod) => mod.SettingsEditLocationComponent),
    data: { breadcrumbTitle: 'Settings - Edit Location' },
  },
  {
    path: '**',
    redirectTo: '',
  },
];
