import { createAction, props } from '@ngrx/store';
import { CateringStore } from 'src/app/core/models/project/cateringStore';

export const getCateringStores = createAction(
  '[CateringStore] Get CateringStores',
  props<{
    callback?: () => void;
  }>()
);

export const getCateringStoresResolved = createAction(
  '[CateringStore] Get CateringStores resolved',
  props<{ cateringStores: CateringStore[] }>()
);

export const getCateringStore = createAction(
  '[CateringStore] Get CateringStore',
  props<{
    ishtarLunchCateringStoreId: string;
    callback?: (cateringStore: CateringStore) => void;
  }>()
);

export const getCateringStoreResolved = createAction(
  '[CateringStore] Get CateringStore Resolved',
  props<{ cateringStore: CateringStore }>()
);

export const deleteCateringStore = createAction(
  '[CateringStore] Delete CateringStore',
  props<{ ishtarLunchCateringStoreId: string; callback?: () => void }>()
);

export const deleteCateringStoreResolved = createAction(
  '[CateringStore] Delete CateringStore Resolved',
  props<{ ishtarLunchCateringStoreId: string }>()
);

export const updateCateringStore = createAction(
  '[CateringStore] Update CateringStore',
  props<{ cateringStore: CateringStore }>()
);

export const updateCateringStoreResolved = createAction(
  '[CateringStore] Update CateringStore Resolved',
  props<{ cateringStore: CateringStore }>()
);

export const addCateringStore = createAction(
  '[CateringStore] Add CateringStore',
  props<{ cateringStore: CateringStore }>()
);

export const addCateringStoreResolved = createAction(
  '[CateringStore] Add CateringStore Resolved',
  props<{ cateringStore: CateringStore }>()
);
