export class Category {
  ishtarLunchCategoryId?: string;
  name!: string;
  description!: string;
  picture?: string;

  constructor(obj: Partial<Category>) {
    Object.assign(this, obj);
  }
}
