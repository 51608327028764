import { Article } from './article';
import { Order } from './order';

export class OrderArticle {
  ishtarLunchOrderArticleId?: string;
  order?: Order;
  article?: Article;
  amount!: number;
  remark!: string;

  constructor(obj: Partial<OrderArticle>) {
    Object.assign(this, obj);
  }
}
