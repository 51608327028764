import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserLicenseInfo } from '../models/user/userlicenseinfo';
import { catchError, map, tap, of } from 'rxjs';
import { GroupUser } from '../models/user/groupuser';
import { AADUser } from '../models/user/aadUser';
import { varlicense$ } from './startup.service';
import { MicrosoftAuthenticationService } from 'processdelight-angular-components';
import { CateringStore } from '../models/project/cateringStore';
import { Ingredient } from '../models/project/ingredient';
import { Article } from '../models/project/article';
import { Category } from '../models/project/category';
import { Order } from '../models/project/order';
import { OrderArticle } from '../models/project/orderArticle';
import { OrderArticleIngredient } from '../models/project/orderArticleIngredient';
import { OrderDaySettings } from '../models/project/orderDaySettings';

@Injectable({
  providedIn: 'root',
})
export class IshtarLunchService {
  apiBase = `${environment.ishtarFunctions}/api`;
  constructor(
    private httpClient: HttpClient,
    private msal: MicrosoftAuthenticationService
  ) {}

  private createApiEndpointUrl(path: string) {
    const url = new URL(`${this.apiBase}/${path}`);
    if (environment.ishtarFunctionsKey.trim() !== '')
      url.searchParams.append('code', environment.ishtarFunctionsKey);
    return url.toString();
  }

  //------------//
  filterQuery(filters: string[]) {
    if (filters.length > 0) {
      const filterString =
        '&expand=Client,Status,ProjectType&select=*,Client,Status,ProjectType&filter=';
      return (
        filterString +
        filters
          .map(
            (filter) =>
              `('${filter}' in ProjectName or '${filter}' in ProjectId or '${filter}' in Status/Status or '${filter}' in Client/ClientName or '${filter}' in ProjectType/Title or '${filter}' in Members)`
          )
          .join(' and ')
      );
    } else {
      return '';
    }
  }
  orderByQuery(column: string, direction: string) {
    if (!column || !direction) return '';
    else return `&orderBy=${column} ${direction.toUpperCase()}`;
  }

  getLicense() {
    return this.httpClient.get<UserLicenseInfo>(
      this.createApiEndpointUrl(
        `license/${this.msal.tenantId}/${this.msal.email}`
      )
    );
  }

  getQuantityUnits() {
    return this.httpClient.get<any>(
      this.createApiEndpointUrl(
        `dataverse/${this.msal.tenantId}/${
          varlicense$.value!.dataverseEnvironmentUrl
        }/IshtarQuantityUnit`
      ),
      {
        headers: {
          ImpersonationUserAADId: varlicense$.value!.microsoftId,
        },
      }
    );
  }

  getTranslations() {
    return this.httpClient.get<any>(
      this.createApiEndpointUrl(
        `ishtarapps/translations?lang=${varlicense$.value?.language}`
      )
    );
  }

  getUsers() {
    return this.httpClient
      .get<GroupUser[]>(
        this.createApiEndpointUrl(`users/${this.msal.tenantId}/Ishtar.Lunch`)
      )
      .pipe(
        map((user) =>
          user.map(
            (u) =>
              new GroupUser({
                user: new AADUser(this.camelcaseKeys((u as any).User!)),
              })
          )
        )
      );
  }

  getGroups() {
    return this.httpClient
      .get<GroupUser[]>(
        this.createApiEndpointUrl(`groups/${this.msal.tenantId}/Ishtar.Lunch`)
      )
      .pipe(
        map((group) => group.map((g) => new GroupUser(this.camelcaseKeys(g))))
      );
  }

  ///////////////////
  // CateringStore //
  ///////////////////

  getCateringStores() {
    return this.httpClient
      .get<CateringStore[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarLunchCateringStore`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((skill) =>
          skill.map((s) => new CateringStore(this.camelcaseKeys(s)))
        )
      );
  }

  getCateringStore(ishtarLunchCateringStoreId: string) {
    return this.httpClient
      .get<CateringStore[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarClient?filter=IshtarClientId eq ${ishtarLunchCateringStoreId} `
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map(
          ([cateringStore]) =>
            new CateringStore(this.camelcaseKeys(cateringStore))
        )
      );
  }

  deleteCateringStore(id: string) {
    return this.httpClient.delete<string[]>(
      this.createApiEndpointUrl(
        `dataverse/${this.msal.tenantId}/${
          varlicense$.value!.dataverseEnvironmentUrl
        }/IshtarLunchCateringStore/records`
      ),
      {
        headers: {
          body: [id],
          ImpersonationUserAADId: varlicense$.value!.microsoftId,
        },
      }
    );
  }

  updateCateringStore(cateringStore: CateringStore) {
    const updatedContact = this.capitalizeKeys(cateringStore);
    return this.httpClient
      .patch<CateringStore[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarLunchCateringStore/records`
        ),
        [updatedContact],
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((contact) =>
          contact.map((c) => new CateringStore(this.camelcaseKeys(c)))
        )
      );
  }

  addCateringStore(cateringStore: CateringStore) {
    const newContact = this.capitalizeKeys(cateringStore);
    return this.httpClient
      .post<CateringStore[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarLunchCateringStore/records`
        ),
        [newContact],
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((contact) =>
          contact.map((c) => new CateringStore(this.camelcaseKeys(c)))
        )
      );
  }

  ///////////////////
  //   Ingredient  //
  ///////////////////

  getIngredient() {
    return this.httpClient
      .get<Ingredient[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarLunchIngredient`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((skill) => skill.map((s) => new Ingredient(this.camelcaseKeys(s))))
      );
  }

  ///////////////////
  //    Article    //
  ///////////////////

  getArticle() {
    return this.httpClient
      .get<Article[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarLunchArticle`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((skill) => skill.map((s) => new Article(this.camelcaseKeys(s))))
      );
  }

  ///////////////////
  //   Category    //
  ///////////////////

  getCategories() {
    return this.httpClient
      .get<Category[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarLunchCategory`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((skill) => skill.map((s) => new Category(this.camelcaseKeys(s))))
      );
  }

  deleteCategory(id: string) {
    return this.httpClient.delete<string[]>(
      this.createApiEndpointUrl(
        `dataverse/${this.msal.tenantId}/${
          varlicense$.value!.dataverseEnvironmentUrl
        }/IshtarLunchCategory/records`
      ),
      {
        headers: {
          body: [id],
          ImpersonationUserAADId: varlicense$.value!.microsoftId,
        },
      }
    );
  }

  updateCategory(category: Category) {
    const updatedContact = this.capitalizeKeys(category);
    return this.httpClient
      .patch<Category[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarLunchCategory/records`
        ),
        [updatedContact],
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((contact) =>
          contact.map((c) => new Category(this.camelcaseKeys(c)))
        )
      );
  }

  addCategory(category: Category) {
    const newContact = this.capitalizeKeys(category);
    return this.httpClient
      .post<Category[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarLunchCategory/records`
        ),
        [newContact],
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((contact) =>
          contact.map((c) => new Category(this.camelcaseKeys(c)))
        )
      );
  }

  ///////////////////
  //     Order     //
  ///////////////////

  getOrder() {
    return this.httpClient
      .get<Order[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarLunchOrder`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(map((skill) => skill.map((s) => new Order(this.camelcaseKeys(s)))));
  }

  // OrderArticle //
  getOrderArticle() {
    return this.httpClient
      .get<OrderArticle[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarLunchOrderArticle`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((skill) =>
          skill.map((s) => new OrderArticle(this.camelcaseKeys(s)))
        )
      );
  }

  // OrderArticleIngredient //
  getOrderArticleIngredient() {
    return this.httpClient
      .get<OrderArticleIngredient[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarLunchOrderArticleIngredient`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((skill) =>
          skill.map((s) => new OrderArticleIngredient(this.camelcaseKeys(s)))
        )
      );
  }

  // OrderDaySettings //
  getOrderDaySettings() {
    return this.httpClient
      .get<OrderDaySettings[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarLunchOrderDaySettings`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((skill) =>
          skill.map((s) => new OrderDaySettings(this.camelcaseKeys(s)))
        )
      );
  }

  camelcaseKeys(obj: any): any {
    if (Array.isArray(obj)) return [...obj.map((o) => this.camelcaseKeys(o))];
    else if (obj instanceof Object)
      return Object.entries(obj).reduce(
        (acc, e) => ({
          ...acc,

          [e[0].charAt(0).toLowerCase() + e[0].slice(1)]: this.camelcaseKeys(
            e[1]
          ),
        }),

        {}
      );
    else return obj;
  }
  capitalizeKeys(obj: any, ...ignoredProperties: string[]): any {
    const ignoredPropertiesLower = ignoredProperties.map((p) =>
      p.toLowerCase()
    );

    if (Array.isArray(obj))
      return [...obj.map((o) => this.capitalizeKeys(o, ...ignoredProperties))];
    else if (obj instanceof Object)
      return Object.entries(obj).reduce(
        (acc, e) => ({
          ...acc,

          [e[0].charAt(0).toUpperCase() + e[0].slice(1)]:
            ignoredPropertiesLower.includes(e[0].toLowerCase())
              ? e[1]
              : this.capitalizeKeys(e[1], ...ignoredProperties),
        }),

        {}
      );
    else return obj;
  }
}
