
import { Category } from "./category";
import { Ingredient } from "./ingredient";

export class Article {
    ishtarLunchArticleId?: string;
    Name!: string;
    Price!: number;
    Description!: string;
    Picture!: string;
    Categories!: Category;
    Ingredients!: Ingredient;
  
    constructor(obj: Partial<Article>) {
      Object.assign(this, obj);
    }
  }
  